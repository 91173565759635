<template>
  <q-dialog
    v-model="open"
  >
    <q-card>
      <q-card-section>
        <div class="text-h6">
          {{ $t('error.default') }}
        </div>
      </q-card-section>
      <q-card-section>
        <div v-if="error && error.message" class="text-h6">
          {{ error.message }}
        </div>
        <div v-if="error && error.detail && error.title" class="text-h6">
          {{ error.title }}
        </div>
        <p v-if="error && error.detail">
          {{ error.detail }}
        </p>
        <q-list>
          <q-item v-for="(e, i) in errorStrings" :key="i">
            {{ e }}
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ['value', 'error'],
  computed: {
    errorStrings () {
      const errors = []
      if (!this.error || !this.error.errors || !Object.keys(this.error.errors).length) return errors
      Object.values(this.error.errors).forEach((prop, i) => {
        prop.forEach((err, i) => {
          errors.push(err)
        })
      })
      return errors
    },
    open: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style>

</style>
